<template>
  <!--begin::Card-->
  <div class="card card-custom clients-basic-info">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title flex-column" style="justify-content: space-evenly">
        <h3 class="card-label font-weight-bolder text-dark">Client Request</h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-info font-weight-bolder mr-2"
          @click="editForm ? save() : edit()"
          ref="kt_save_changes"
        >
          {{ editForm ? "Save" : "Edit" }}
        </button>
        <button
          type="reset"
          class="btn btn-secondary font-weight-bolder"
          @click="cancel()"
          v-if="editForm"
        >
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" @submit.prevent="save">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
        </div>

        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Official Squad Name</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <template v-if="profileDataProject && profileDataProject.name">
                {{ profileDataProject.name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>
            <template v-else>
              <v-text-field
                outlined
                dense
                hide-details
                v-model.trim="form.squadName"
                @blur="$v.form.squadName.$touch()"
              ></v-text-field>
              <VulidateError
                v-if="editForm"
                :validationField="$v.form.squadName"
                :params="[
                  {
                    maxLength: {
                      number: $v.form.squadName.$params.maxLength.max,
                    },
                  },
                ]"
              />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Internal squad name</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <template
                v-if="profileDataProject && profileDataProject.internal_name"
              >
                {{ profileDataProject.internal_name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>
            <template v-else>
              <v-text-field
                outlined
                dense
                hide-details
                v-model.trim="form.squadInternalName"
                @blur="$v.form.squadInternalName.$touch()"
              ></v-text-field>
              <VulidateError
                v-if="editForm"
                :validationField="$v.form.squadInternalName"
                :params="[
                  {
                    maxLength: {
                      number: $v.form.squadInternalName.$params.maxLength.max,
                    },
                  },
                ]"
              />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Squad brief</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <template
                v-if="profileDataProject && profileDataProject.description"
              >
                {{ profileDataProject.description }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>

            <template v-else>
              <v-textarea
                outlined
                dense
                hide-details
                rows="3"
                :no-resize="true"
                v-model.trim="form.squadBrief"
                @blur="$v.form.squadBrief.$touch()"
              ></v-textarea>
              <VulidateError
                :validationField="$v.form.squadBrief"
                :params="[
                  {
                    maxLength: {
                      number: $v.form.squadBrief.$params.maxLength.max,
                    },
                  },
                ]"
              />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Regulation Country</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <span
                class="profile.client_project.project_type.name"
                v-if="
                  profileDataProject &&
                  profileDataProject.regulation_country &&
                  profileDataProject.regulation_country.name
                "
              >
                {{ profileDataProject.regulation_country.name }}
              </span>
              <span class="profile.client_project.project_type.name" v-else>
                {{ "-" }}
              </span>
            </span>
            <template v-else>
              <v-select
                :items="filteredregulationCountry"
                item-text="name"
                item-value="id"
                dense
                hide-details
                outlined
                v-model="form.regulationCountry"
                @change="regulationCountrySearch = ''"
              >
                <template v-slot:prepend-item>
                  <div class="px-5 mb-4">
                    <v-text-field
                      v-model.trim="regulationCountrySearch"
                      placeholder="Search"
                      hide-details
                      class="mt-0 pt-0 text-h6 w-100"
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Industry</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <span
                class="profile.client_project.project_type.name"
                v-if="
                  profileDataProject &&
                  profileDataProject.project_type &&
                  profileDataProject.project_type.name
                "
              >
                {{ profileDataProject.project_type.name }}
              </span>
              <span class="profile.client_project.project_type.name" v-else>
                {{ "-" }}
              </span>
            </span>
            <template v-else>
              <v-select
                :items="filteredIndustries"
                item-text="name"
                item-value="id"
                dense
                hide-details
                outlined
                v-model="form.industry"
                @blur="$v.form.industry.$touch()"
                @change="industrySearch = ''"
              >
                <template v-slot:prepend-item>
                  <div class="px-5 mb-4">
                    <v-text-field
                      v-model.trim="industrySearch"
                      placeholder="Search"
                      hide-details
                      class="mt-0 pt-0 text-h6 w-100"
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
              <VulidateError
                :validationField="$v.form.industry"
                :params="['required']"
              />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Utilization Type</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <template
                v-if="profileDataProject && profileDataProject.hiring_type"
              >
                <div class="mr-1 d-inline-block">
                  {{
                    profileDataProject.hiring_type == 1
                      ? "Full-time"
                      : profileDataProject.hiring_type == 2
                      ? "Part-time"
                      : "Depends on the cost"
                  }}
                </div>
              </template>
              <template v-else> {{ "-" }}</template>
            </span>
            <template v-else>
              <v-select
                :items="utilizations"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                v-model="form.utilizationType"
                @blur="$v.form.utilizationType.$touch()"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Technologies</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold d-block"
            >
              <template v-if="profileDataProject && uniqueTechnologies.length">
                <div
                  v-for="(tech, i) in uniqueTechnologies"
                  :key="i"
                  class="mr-1 d-inline-block"
                >
                  {{ tech.name }}
                  <span v-if="uniqueTechnologies.length - 1 !== i">,</span>
                </div>
              </template>
              <template v-else> {{ "-" }}</template>
            </span>
            <template v-else>
              <v-select
                :items="filteredTechnologies"
                item-text="name"
                item-value="id"
                dense
                hide-details
                outlined
                multiple
                disabled
                v-model="form.technologies"
                @blur="$v.form.technologies.$touch()"
                @change="technologySearch = ''"
                @input="techAdded"
              >
                <template v-slot:prepend-item>
                  <div class="px-5 mb-4">
                    <v-text-field
                      v-model.trim="technologySearch"
                      placeholder="Search"
                      hide-details
                      class="mt-0 pt-0 text-h6 w-100"
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
              <VulidateError :validationField="$v.form.technologies" />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Services</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold d-block"
            >
              <template
                v-if="
                  profileDataProject &&
                  profileDataProject.services &&
                  profileDataProject.services.length
                "
              >
                <div
                  v-for="(service, i) in profileDataProject.services"
                  :key="service.service"
                  class="mr-1 d-inline-block"
                >
                  {{
                    serviceTypeOptions.find(
                      (option) => option.value == service.service
                    ).text
                  }}
                  <span v-if="profileDataProject.services.length - 1 !== i"
                    >,</span
                  >
                </div>
              </template>
              <template v-else> {{ "-" }}</template>
            </span>
            <template v-else>
              <v-select
                :items="serviceTypeOptions"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                multiple
                v-model="form.services"
                @blur="$v.form.services.$touch()"
                :menu-props="{ left: true, offsetY: true }"
              ></v-select>
              <VulidateError
                :validationField="$v.form.services"
                :params="['required']"
              />
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Need Consultation</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <template v-if="profileDataProject">
                {{
                  profileDataProject.need_advice
                    ? "Yes"
                    : profileDataProject.need_advice == null
                    ? "-"
                    : "No"
                }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>
            <template v-else>
              <v-select
                :items="yesNoOptions"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                v-model="form.needConsultation"
                @blur="$v.form.needConsultation.$touch()"
                :menu-props="{ left: true, offsetY: true }"
              ></v-select>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Build App from Scratch</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <template v-if="profileDataProject">
                {{
                  profileDataProject.from_scratch
                    ? "Yes"
                    : profileDataProject.from_scratch == null
                    ? "-"
                    : "No"
                }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>
            <template v-else>
              <v-select
                :items="yesNoOptions"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                v-model="form.fromScratch"
                @blur="$v.form.fromScratch.$touch()"
                :menu-props="{ left: true, offsetY: true }"
              ></v-select>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Need technology advice</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              v-if="!editForm"
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
            >
              <template v-if="profileDataProject">
                {{
                  profileDataProject.need_technology_advice
                    ? "Yes"
                    : profileDataProject.need_technology_advice == null
                    ? "-"
                    : "No"
                }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </span>
            <span class="switch switch-sm" v-else>
              <label>
                <input
                  type="checkbox"
                  checked="checked"
                  v-model="form.needTechnologyAdvice"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Comments and Notes</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <p v-if="profileDataProject && profileDataProject.notes_comments">
                {{ profileDataProject.notes_comments }}
              </p>
              <template v-else>
                {{ "-" }}
              </template>
            </span>

            <template v-else>
              <v-textarea
                outlined
                dense
                hide-details
                v-model.trim="form.notes_comments"
                rows="3"
                :no-resize="true"
                @blur="$v.form.notes_comments.$touch()"
              ></v-textarea>
              <VulidateError
                :validationField="$v.form.notes_comments"
                :params="[
                  {
                    maxLength: {
                      number: $v.form.notes_comments.$params.maxLength.max,
                    },
                  },
                ]"
              />
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Company Website</label
          >
          <div
            class="col-lg-9 col-xl-6"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              {{ profileDataProject.website }}
            </span>

            <template v-else>
              <v-text-field
                outlined
                dense
                hide-details
                v-model.trim="form.website"
              ></v-text-field>
            </template>
          </div>
        </div>
        <div
          v-if="
            profileDataProject &&
            profileDataProject.signup_requests &&
            profileDataProject.signup_requests.length > 0
          "
          class="form-group row"
        >
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Roles Required</label
          >
          <div
            class="col-lg-9 col-xl-9"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <div class="table-responsive mb-0">
                <table
                  class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                >
                  <thead>
                    <tr class="text-left">
                      <th>Role</th>
                      <th>Primary Language</th>
                      <th>Secondary Language</th>
                      <th>Utitization Type</th>
                      <th>Duration</th>
                      <th>Added by</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="request in profileDataProject.signup_requests"
                      :key="request.uuid"
                    >
                      <td>
                        {{ request.role ? request.role?.name : "Need advice" }}
                      </td>
                      <td v-if="request.role">
                        {{
                          request.role.id != 1 &&
                          request.role.id != 2 &&
                          request.role.id != 3
                            ? "--------"
                            : request?.role?.id == 3 || request?.role?.id == 1
                            ? request?.be_lang?.name
                            : request?.fe_lang?.name
                        }}
                      </td>
                      <td v-else>--------</td>
                      <td v-if="request.role">
                        {{
                          request.role.id != 3
                            ? "--------"
                            : request?.fe_lang.name
                        }}
                      </td>
                      <td v-else>--------</td>

                      <td>
                        {{
                          request.availability?.name ||
                          request.availability ||
                          "------"
                        }}
                      </td>
                      <td>
                        {{
                          request.duration?.name ||
                          allDurationsList.find(
                            (option) => option.id == request.duration
                          )?.name ||
                          "-------"
                        }}
                      </td>
                      <td class="d-flex flex-column">
                        <span>{{ request.created_by?.name }}</span>
                        <span>{{ request.created_by.email }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>

            <template v-else>
              <div
                class="row form-group"
                v-for="(request, index) in form.signup_requests"
                :key="request.uuid + '-form'"
              >
                <div
                  class="col-lg-2"
                  v-b-tooltip.hover.top="
                    `${
                      request.role && request.role.id ? request.role.name : '-'
                    }`
                  "
                >
                  <v-select
                    placeholder="Roles"
                    :items="roles"
                    label="Roles"
                    return-object
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    :disabled="!roles.length"
                    v-model="request.role"
                    @change="roleChanged(index)"
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.role.id == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div v-if="request?.role?.id == 3 || request?.role?.id == 1">
                    <v-select
                      :items="backEndLanguages"
                      item-text="name"
                      item-value="id"
                      :placeholder="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                          ? '------------'
                          : 'Primary language'
                      "
                      outlined
                      :disabled="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                      "
                      v-model="request.be_lang"
                      dense
                      hide-details
                      :menu-props="{ left: true, offsetY: true }"
                    ></v-select>
                    <div
                      class="form__error-label"
                      v-if="request.be_lang == null && isSubmitted"
                    >
                      This field is required.
                    </div>
                  </div>
                  <div v-else>
                    <v-select
                      :items="frontEndLanguages"
                      item-text="name"
                      item-value="id"
                      :placeholder="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                          ? '------------'
                          : 'Primary language'
                      "
                      outlined
                      :disabled="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                      "
                      v-model="request.fe_lang"
                      dense
                      hide-details
                      :menu-props="{ left: true, offsetY: true }"
                    ></v-select>
                    <div
                      class="form__error-label"
                      v-if="
                        (request?.role?.id == 3 ||
                          request?.role?.id == 1 ||
                          request?.role?.id == 2) &&
                        request.fe_lang == null &&
                        isSubmitted
                      "
                    >
                      This field is required.
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <v-select
                    :items="frontEndLanguages"
                    item-text="name"
                    item-value="id"
                    :placeholder="
                      request?.role?.id !== 3
                        ? '------------'
                        : 'Secondary language'
                    "
                    outlined
                    v-model="request.fe_lang"
                    :disabled="request?.role?.id != 3"
                    dense
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="
                      request?.role?.id == 3 &&
                      request.fe_lang == null &&
                      isSubmitted
                    "
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <v-select
                    :items="utilizationsRolesRequired"
                    item-text="name"
                    item-value="id"
                    return-object
                    placeholder="Utilization Type"
                    v-model="request.availability"
                    outlined
                    dense
                    hide-details
                    @change="availabilityChanged(index)"
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.availability == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div
                  v-if="request.durationsList && request.durationsList.length"
                  class="col-lg-2"
                >
                  <v-select
                    :items="request.durationsList"
                    placeholder="Duration"
                    item-text="name"
                    return-object
                    item-value="id"
                    v-model="request.duration"
                    outlined
                    dense
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.duration == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <b-button
                    v-if="index == 0"
                    size="sm"
                    class="ml-5 signup-requests"
                    variant="outline-info"
                    @click.stop="addRole()"
                  >
                    <img
                      src="@/assets/images/icons/add.png"
                      style="height: 24px; width: 24px"
                    />
                  </b-button>

                  <b-button
                    size="sm"
                    class="ml-5 signup-requests"
                    variant="outline-info"
                    v-if="index != 0"
                    @click="removeRole(index)"
                  >
                    <img
                      src="@/assets/images/icons/delete.png"
                      style="height: 24px; width: 24px"
                    />
                  </b-button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Roles Required</label
          >
          <div
            class="col-lg-9 col-xl-9"
            :class="{ 'col-form-label': !editForm }"
          >
            <span
              class="text-dark-50 mb-1 font-size-lg font-weight-bold"
              v-if="!editForm"
            >
              <div class="table-responsive mb-0">
                <table
                  class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                >
                  <thead>
                    <tr class="text-left">
                      <th>Role</th>
                      <th>Primary Language</th>
                      <th>Secondary Language</th>
                      <th>Utitization Type</th>
                      <th>Duration</th>
                      <th>Added by</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="request in rolesList" :key="request.uuid">
                      <td>
                        {{ request.name ? request?.name : "------------" }}
                      </td>
                      <td v-if="request.role">
                        {{
                          request.id != 1 && request.id != 2 && request.id != 3
                            ? "--------"
                            : request?.id == 3 || request?.id == 1
                            ? request?.be_lang?.name
                            : request?.fe_lang?.name
                        }}
                      </td>
                      <td v-else>--------</td>
                      <td v-if="request.role">
                        {{
                          request.id != 3 ? "--------" : request?.fe_lang.name
                        }}
                      </td>
                      <td v-else>--------</td>

                      <td>
                        {{
                          request.availability?.name ||
                          request.availability ||
                          "------"
                        }}
                      </td>
                      <td>
                        {{
                          request.duration?.name ||
                          allDurationsList.find(
                            (option) => option.id == request.duration
                          )?.name ||
                          "-------"
                        }}
                      </td>
                      <td class="d-flex flex-column">
                        <span>{{ "---" }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>

            <template v-else>
              <div
                class="row form-group"
                v-for="(request, index) in form.signup_requests"
                :key="request.uuid + '-form'"
              >
                <div
                  class="col-lg-2"
                  v-b-tooltip.hover.top="
                    `${
                      request.role && request.role.id ? request.role.name : '-'
                    }`
                  "
                >
                  <v-select
                    placeholder="Roles"
                    :items="roles"
                    label="Roles"
                    return-object
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    :disabled="!roles.length"
                    v-model="request.role"
                    @change="roleChanged(index)"
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.role.id == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div v-if="request?.role?.id == 3 || request?.role?.id == 1">
                    <v-select
                      :items="backEndLanguages"
                      item-text="name"
                      item-value="id"
                      :placeholder="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                          ? '------------'
                          : 'Primary language'
                      "
                      outlined
                      :disabled="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                      "
                      v-model="request.be_lang"
                      dense
                      hide-details
                      :menu-props="{ left: true, offsetY: true }"
                    ></v-select>
                    <div
                      class="form__error-label"
                      v-if="request.be_lang == null && isSubmitted"
                    >
                      This field is required.
                    </div>
                  </div>
                  <div v-else>
                    <v-select
                      :items="frontEndLanguages"
                      item-text="name"
                      item-value="id"
                      :placeholder="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                          ? '------------'
                          : 'Primary language'
                      "
                      outlined
                      :disabled="
                        request?.role?.id != 3 &&
                        request?.role?.id != 1 &&
                        request?.role?.id != 2
                      "
                      v-model="request.fe_lang"
                      dense
                      hide-details
                      :menu-props="{ left: true, offsetY: true }"
                    ></v-select>
                    <div
                      class="form__error-label"
                      v-if="
                        (request?.role?.id == 3 ||
                          request?.role?.id == 1 ||
                          request?.role?.id == 2) &&
                        request.fe_lang == null &&
                        isSubmitted
                      "
                    >
                      This field is required.
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <v-select
                    :items="frontEndLanguages"
                    item-text="name"
                    item-value="id"
                    :placeholder="
                      request?.role?.id !== 3
                        ? '------------'
                        : 'Secondary language'
                    "
                    outlined
                    v-model="request.fe_lang"
                    :disabled="request?.role?.id != 3"
                    dense
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="
                      request?.role?.id == 3 &&
                      request.fe_lang == null &&
                      isSubmitted
                    "
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <v-select
                    :items="utilizationsRolesRequired"
                    item-text="name"
                    item-value="id"
                    return-object
                    placeholder="Utilization Type"
                    v-model="request.availability"
                    outlined
                    dense
                    hide-details
                    @change="availabilityChanged(index)"
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.availability == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div
                  v-if="request.durationsList && request.durationsList.length"
                  class="col-lg-2"
                >
                  <v-select
                    :items="request.durationsList"
                    placeholder="Duration"
                    item-text="name"
                    return-object
                    item-value="id"
                    v-model="request.duration"
                    outlined
                    dense
                    hide-details
                    :menu-props="{ left: true, offsetY: true }"
                  ></v-select>
                  <div
                    class="form__error-label"
                    v-if="request.duration == null && isSubmitted"
                  >
                    This field is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <b-button
                    v-if="index == 0"
                    size="sm"
                    class="ml-5 signup-requests"
                    variant="outline-info"
                    @click.stop="addRole()"
                  >
                    <img
                      src="@/assets/images/icons/add.png"
                      style="height: 24px; width: 24px"
                    />
                  </b-button>

                  <b-button
                    size="sm"
                    class="ml-5 signup-requests"
                    variant="outline-info"
                    v-if="index != 0"
                    @click="removeRole(index)"
                  >
                    <img
                      src="@/assets/images/icons/delete.png"
                      style="height: 24px; width: 24px"
                    />
                  </b-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import VulidateError from "@/components/shared/vulidateError.vue";
import { required, maxLength } from "vuelidate/lib/validators";

const initForm = {
  squadName: null,
  squadInternalName: null,
  squadBrief: null,
  industry: {},
  regulationCountry: null,
  utilizationType: null,
  technologies: [],
  services: [],
  needConsultation: null,
  fromScratch: null,
  needTechnologyAdvice: false,
  notes_comments: null,
  website: null,
  signup_requests: [],
};
export default {
  name: "ClientBasicInfo",
  mixins: [validationMixin],
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  components: {
    VulidateError,
  },
  data() {
    return {
      isSubmitted: false,
      durationsList: [
        { id: 6, name: "6 months" },
        { id: 12, name: "12 months" },
        { id: 24, name: "24 months" },
        { id: 36, name: "36 months" },
      ],
      allDurationsList: [
        { id: 3, name: "3 months" },
        { id: 6, name: "6 months" },
        { id: 12, name: "12 months" },
        { id: 24, name: "24 months" },
        { id: 36, name: "36 months" },
      ],
      editForm: false,
      industrySearch: null,
      regulationCountrySearch: null,
      technologySearch: null,
      previousSelectedTechnologies: [],
      yesNoOptions: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      serviceTypeOptions: [
        {
          text: "Team as a serivce",
          value: 1,
        },
        {
          text: "Creative Lab",
          value: 2,
        },
        {
          text: "CTO as a serivce",
          value: 3,
        },
        {
          text: "Payroll Management",
          value: 4,
        },
      ],
      utilizations: [
        {
          text: "Full-time",
          value: 1,
        },
        {
          text: "Part-time",
          value: 2,
        },
        {
          text: "Depends on the cost",
          value: 3,
        },
      ],
      utilizationsRolesRequired: [
        {
          name: "Full-time",
          id: 2,
        },
        {
          name: "Part-time",
          id: 1,
        },
      ],
      form: { ...initForm },
    };
  },
  validations: {
    form: {
      squadName: {
        maxLength: maxLength(50),
      },
      squadInternalName: {
        maxLength: maxLength(50),
      },
      squadBrief: {
        maxLength: maxLength(500),
      },
      notes_comments: {
        maxLength: maxLength(2500),
      },
      industry: {
        required,
      },
      utilizationType: {
        // Remove 'required' validation
      },
      fromScratch: {
        // Remove 'required' validation
      },
      needConsultation: {
        // Remove 'required' validation
      },
      services: {
        required,
      },
    },
  },
  watch: {
    editForm: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.previousSelectedTechnologies = [
            ...(this.profileDataProject?.technologies || []).map((tech) => {
              return { ...tech, other: false };
            }),
            ...(this.profileDataProject?.others_technologies || []).map(
              (tech) => {
                return { ...tech, other: true };
              }
            ),
          ];

          let oldOtherTechnology = [];
          oldOtherTechnology = (this.profileDataProject?.technologies || [])
            .filter(
              (t) =>
                t.role_skill &&
                !(this.profileDataProject?.others_technologies || []).find(
                  (o) => o.id == t.other_id
                )
            )
            .map((ot) => this.technologies.find((t) => t.id == ot.other_id));

          console.log("rolesList", this.rolesList);

          this.form = {
            squadName: this.profileDataProject?.name || "",
            squadInternalName: this.profileDataProject?.internal_name || "",
            squadBrief: this.profileDataProject?.description || "",
            regulationCountry:
              this.profileDataProject?.regulation_country?.id || null,
            notes_comments: this.profileDataProject?.notes_comments || "",
            industry: this.profileDataProject?.project_type || {},
            utilizationType: this.profileDataProject?.hiring_type || null,
            technologies: [
              ...(this.profileDataProject?.technologies || []).map((tech) => {
                return { ...tech, other: false };
              }),
              ...(this.profileDataProject?.others_technologies || []).map(
                (tech) => {
                  return { ...tech, other: true };
                }
              ),
              ...oldOtherTechnology.map((tech) => {
                return { ...tech, other: true };
              }),
            ],
            services: [
              ...(this.profileDataProject?.services || []).map(
                (s) => s.service
              ),
            ],
            needConsultation: this.profileDataProject.need_advice,
            fromScratch: this.profileDataProject.from_scratch,
            needTechnologyAdvice:
              this.profileDataProject?.need_technology_advice || false,
            website: this.profileDataProject?.website || "",
            signup_requests:
              (this.profileDataProject?.signup_requests || []).length > 0
                ? (this.profileDataProject?.signup_requests || [])
                    .slice(0, 10)
                    .map((r) => {
                      return {
                        ...r,
                        role: r.role ? r.role : { id: null },
                        uuid: this.generateuuid(),
                        durationsList:
                          r.availability && r.availability.id == 1
                            ? [
                                { id: 3, name: "3 months" },
                                ...this.durationsList,
                              ]
                            : this.durationsList,
                      };
                    })
                : this.rolesList.map((r) => {
                    return {
                      ...r,
                      role: r,
                      uuid: this.generateuuid(),
                      durationsList:
                        r.availability && r.availability.id == 1
                          ? [{ id: 3, name: "3 months" }, ...this.durationsList]
                          : this.durationsList,
                    };
                  }),
          };

          if ((this.profileDataProject?.signup_requests || []).length > 0) {
            setTimeout(() => {
              this.form.signup_requests = (
                this.profileDataProject?.signup_requests || []
              ).map((r) => {
                return {
                  ...r,
                  role: r.role ? r.role : { id: null },
                  uuid: this.generateuuid(),
                  durationsList:
                    r.availability && r.availability.id == 1
                      ? [{ id: 3, name: "3 months" }, ...this.durationsList]
                      : this.durationsList,
                };
              });
            }, 200);
          } else {
            if (this.form.signup_requests.length) return;
            this.form.signup_requests = [
              {
                role: {
                  id: null,
                },
                uuid: this.generateuuid(),
                be_lang: null,
                fe_lang: null,
                availability: null,
                duration: null,
                durationsList: [...this.durationsList],
              },
            ];
          }
        }
      },
    },
    $route(to, from) {
      if (to.params.project_id !== from.params.project_id) {
        this.getCurrentRoles();
      }
      // Add any additional logic you need to handle on route change
    },
  },
  created() {
    this.getIndustries();
    this.getTechnologies();
    this.getCountryList();
    this.getRoles(true);
    this.getFrontEndLanguages();
    this.getBackEndLanguages();
    this.getCurrentRoles();
  },
  methods: {
    ...mapActions({
      getIndustries: "clients/getIndustries",
      getTechnologies: "clients/getTechnologies",
      setClientsData: "clients/setClientsData",
      setClientsDataProject: "clients/setClientsDataProject",
      getCountryList: "shared/getCountryList",
      getRoles: "talents/getRoles",
      getFrontEndLanguages: "talentProfile/getFrontEndLanguages",
      getBackEndLanguages: "talentProfile/getBackEndLanguages",
      getRolesProject: "clients/getRolesProject",
      getFilteredRoles: "clients/getFilteredRoles",
    }),
    getCurrentRoles() {
      if (this.$route.params.project_id) {
        const payload = {
          project_id: this.$route.params.project_id,
          client_id: this.$route.params.id,
        };
        this.getFilteredRoles(payload);
      }
    },
    generateuuid() {
      return Math.floor(Math.random() * 1000000);
    },
    roleChanged(index) {
      this.form.signup_requests[index].be_lang = null;
      this.form.signup_requests[index].fe_lang = null;
    },
    availabilityChanged(index) {
      if (this.form.signup_requests[index].availability.id == 2) {
        this.form.signup_requests[index].durationsList = this.durationsList;
      } else {
        this.form.signup_requests[index].durationsList = [
          { id: 3, name: "3 months" },
          ...this.durationsList,
        ];
      }
    },
    removeRole(index) {
      this.form.signup_requests.splice(index, 1);
    },
    addRole() {
      this.form.signup_requests.push({
        role: {
          id: null,
        },
        uuid: this.generateuuid(),
        be_lang: null,
        fe_lang: null,
        availability: null,
        duration: null,
        durationsList: [...this.durationsList],
      });
    },
    techAdded(techs) {
      let added = techs.filter(
        (val) =>
          !this.previousSelectedTechnologies.map((t) => t.id).includes(val)
      );
      let removed = this.previousSelectedTechnologies.filter(
        (val) => !techs.includes(val.id)
      );

      if (added) {
        added.map((addTech) => {
          let tech = this.technologies.find((t) => t.id == addTech);

          if (
            tech.role_skill &&
            tech.other_id &&
            !this.form.technologies.includes(tech.other_id)
          ) {
            this.form.technologies = [...this.form.technologies, tech.other_id];
          }
        });
      }

      if (removed) {
        removed.map((removeTech) => {
          this.form.technologies = this.form.technologies.filter(
            (t) => t !== removeTech.id
          );
          if (removeTech.other_id) {
            this.form.technologies = this.form.technologies.filter(
              (t) => t !== removeTech.other_id
            );
          }
        });
      }

      this.previousSelectedTechnologies = this.form.technologies.map((t) =>
        this.technologies.find((tech) => tech.id == t)
      );
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    edit() {
      this.editForm = true;
    },
    save() {
      this.$v.form.$touch();
      this.isSubmitted = true;
      // check if there is any error in this.form.signup_requests using js it must have availability and duration role id and be_lang or fe_lang if the role is 3 , be_lang if the role is 1 , fe_lang  if the role is 2 , role only for any anther role
      if (
        this.form.signup_requests.find(
          (r) =>
            !r.availability ||
            !r.duration ||
            !r.role.id ||
            (r.role.id == 1 && !r.be_lang) ||
            (r.role.id == 2 && !r.fe_lang) ||
            (r.role.id == 3 && !r.fe_lang) ||
            !r.role
        )
      ) {
        return;
      }

      if (this.$v.$anyError) {
        return;
      }
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let others = [];
      let technologies = [];

      technologies = this.form.technologies
        .filter((tech) => {
          if (
            this.technologies.find(
              (t) => t.id === (tech.id ? tech.id : tech) && t.other == false
            )
          ) {
            return tech.id ? tech.id : tech;
          }
        })
        .map((t) => (t.id ? t.id : t));

      technologies.map((Tech) => {
        let tech = this.technologies.find((t) => t.id == Tech);
        others = [
          ...others,
          ...this.technologies
            .filter((t) => tech.name === t.name)
            .map((t) => (t.id ? t.id : t)),
        ];
      });

      others = this.form.technologies
        .filter((tech) => {
          if (
            this.technologies.find(
              (t) => t.id === (tech.id ? tech.id : tech) && t.other == true
            ) &&
            !technologies.find((t) => t == tech)
          ) {
            return tech.id ? tech.id : tech;
          }
        })
        .map((t) => (t.id ? t.id : t));

      others.map((oTech) => {
        let tech = this.technologies.find((t) => t.id == oTech);
        others = [
          ...others,
          ...this.technologies
            .filter((t) => tech.name === t.name)
            .map((t) => (t.id ? t.id : t)),
        ];
      });

      technologies = [...new Set(technologies)];

      others = [...new Set(others)].filter((t) => !technologies.includes(t));

      let payload = null;

      payload = {
        about: {
          name: this.form.squadName,
          internal_name: this.form.squadInternalName,
          project_type_id: this.form.industry.id
            ? this.form.industry.id
            : this.form.industry,
          description: this.form.squadBrief,
          regulation_country_id: this.form.regulationCountry,
          website: this.form.website,
        },
        services: this.form.services,
        technologies: {
          technologies,
          others,
          need_technology_advice: this.form.needTechnologyAdvice ? 1 : 0,
        },
        details: {
          hiring_type: this.form.utilizationType,
          from_scratch: this.form.fromScratch ? 1 : 0,
          need_advice: this.form.needConsultation ? 1 : 0,
        },
        client_id: this.$route.params.id,
        project_id: this.$route.params.project_id,
        notes_comments: this.form.notes_comments,
        website: this.form.website,
        signup_requests: this.form.signup_requests.map((r) => {
          return {
            role_id: r.role.id,
            be_lang_id: r.be_lang?.id ? r.be_lang.id : r.be_lang,
            fe_lang_id: r.fe_lang?.id ? r.fe_lang.id : r.fe_lang,
            availability: r.availability.id,
            duration: r.duration.id ? r.duration.id : r.duration,
          };
        }),
      };

      this.setClientsDataProject(payload)
        .then(() => {
          this.editForm = false;
          this.$emit("update");
          // this.$router.push({
          //   name: "client-profile",
          //   params: { project_id: this.$route.params.id },
          // });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch(() => {
          this.editForm = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    cancel() {
      this.editForm = false;
      this.isSubmitted = false;
      this.$v.$reset();
    },
  },
  computed: {
    ...mapGetters({
      industries: "clients/getIndustries",
      technologies: "clients/getTechnologies",
      countryList: "shared/getCountryList",
      profileDataProject: "clients/getProfileDataProject",
      roles: "talents/getRoles",
      frontEndLanguages: "talentProfile/getFrontEndLanguages",
      backEndLanguages: "talentProfile/getBackEndLanguages",
      rolesList: "clients/getRoles",
    }),
    uniqueTechnologies() {
      return this.profileDataProject.technologies ||
        this.profileDataProject.others_technologies
        ? this.getUniqueListBy(
            [
              ...this.profileDataProject?.technologies,
              ...this.profileDataProject?.others_technologies,
            ],
            "name"
          )
        : [];
    },
    filteredIndustries() {
      return !this.industrySearch
        ? this.industries
        : this.industries.filter((skill) =>
            skill.name.toLowerCase().includes(this.industrySearch.toLowerCase())
          );
    },
    filteredregulationCountry() {
      return !this.regulationCountrySearch
        ? this.countryList
        : this.countryList.filter((c) =>
            c.name
              .toLowerCase()
              .includes(this.regulationCountrySearch.toLowerCase())
          );
    },
    filteredTechnologies() {
      return !this.technologySearch
        ? this.getUniqueListBy(this.technologies, "name")
        : this.getUniqueListBy(this.technologies, "name").filter((technology) =>
            technology.name
              .toLowerCase()
              .includes(this.technologySearch.toLowerCase())
          );
    },

    selectedServices() {
      return this.profileDataProject.services.map((s) => s.service);
    },
    selectedTechnology() {
      return [
        ...this.profileDataProject.technologies,
        ...this.profileDataProject.others_technologies,
      ];
    },
  },
};
</script>

<style>
.form .v-text-field--outlined,
.form .v-text-field--solo {
  border-radius: 4px;
}

.clients-basic-info .form-group {
  margin-bottom: 1rem;
}
</style>

<style>
.signup-requests.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(
    .disabled
  ),
.btn.btn-outline-info:focus:not(.btn-text),
.btn.btn-outline-info.focus:not(.btn-text) {
  background-color: #fff;
}
</style>
